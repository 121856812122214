module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NWCPDK4","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Printdeal.be","short_name":"Printdeal.be","start_url":"/","lang":"nl-BE","background_color":"#e8712c","theme_color":"#e8712c","display":"standalone","legacy":false,"icon":"./node_modules/@printdeal/brand/printdeal/images/production-android-chrome-512x512.png","localize":[{"start_url":"/nl","lang":"nl"},{"start_url":"/fr","lang":"fr"}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e667a356d0d1a6b2db386d6fa3d259c1"},
    },{
      plugin: require('../plugins/gatsby-plugin-auto-refresh/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
